.root {
}

.selectError {
  border-bottom: var(--borderErrorField);
}
/* Container */
.fieldContainer {
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 20px;
}

/* Label */
.field-label {
  font-weight: bold;
  margin-bottom: 6px;
}

/* Select Field */
.fieldSelect {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  border:1px solid #F0F2F6;
  padding: 8px 32px 8px 8px;
  border-radius: 6px;
  min-height: 40px;
  cursor: pointer;
  background: #F0F2F6;
}

/* Selected Tags */
.fieldTag {
  background-color: #B6030A;
  color: white;
  padding: 4px 10px;
  border-radius: 8px;
  margin-right: 6px;
  display: flex;
  align-items: center;
  margin-bottom: 4px;
}

.fieldTagRemove {
  background: none;
  border: none;
  color: white;
  font-size: 14px;
  margin-left: 6px;
  cursor: pointer;
}

/* Placeholder Text */
.fieldPlaceholder {
  color: gray;
}

/* Clear All Button */
.fieldClear {
  position: absolute;
  right: 32px;
  background: none;
  border: none;
  font-size: 16px;
  color: gray;
  cursor: pointer;
}

/* Custom Dropdown */
.fieldDropdown {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  /* background: white; */
  border: 1px solid #6d6d6d;
  border-radius: 8px;
  margin-top: 5px;
  padding: 5px 0;
  list-style: none;
  z-index: 9999; /* Ensure it's above the footer */
  max-height: 200px;
  overflow-y: auto;
  background: #F0F2F6;
  color: black;
}

.fieldDropdownUp {
  top: auto;
  bottom: 80%; 
  /* margin-bottom: 4px; */
}

.fieldDropdown li {
  padding: 8px;
  cursor: pointer;
}

.fieldDropdown li:hover {
  background: rgba(255, 0, 0, 0.1);
}

/* Highlight Selected Option */
.selectedOption {
  background: lightgray !important;
  color: black;
  cursor: default;
}

.fieldSelectIcon {
  position: absolute;
  right: 10px;
  background: none;
  border: none;
  font-size: 16px;
  color: gray;
  cursor: pointer;
}

.paddingWithoutTop{
  padding:0px 20px 20px 20px;
}

/* Always Show Scrollbar (for Chrome, Edge, Safari) */
.fieldDropdown::-webkit-scrollbar {
  width: 6px;
  background: #f0f0f0; /* Light background so scrollbar is always visible */
}

.fieldDropdown::-webkit-scrollbar-thumb {
  background: #bbb; /* Keeps scrollbar thumb visible */
  border-radius: 3px;
  min-height: 20px; /* Minimum height of the scrollbar thumb */
  max-height: 50px; /* Maximum height */
}

.fieldDropdown::-webkit-scrollbar-track {
  background: #f0f0f0; /* Ensures track is visible */
}