@import '../../../styles/customMediaQueries.css';

/* Desktop */
.root {
  /* Size */
  width: 100%;
  height: var(--topbarHeightDesktop);

  /* Layout for child components */
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  justify-content: space-between;
  align-items: center;
  gap: 36px;

  /* Padding */
  padding: 0 36px;

  /* fill */
  background-color: var(--colorWhite);

  /* @media (--viewportMedium) {
    grid-template-columns: flex;
    gap: 24px;
  } */

  @media (min-width:768px) and (max-width:1200px) {
    display: flex;
  }

  @media (--viewportMLarge) {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

.leftNavigation,
.rightNavigation {
  /* Layout */
  display: flex;
  align-items: center;
  gap: 24px;

  @media (min-width: 820px) {
    gap: 36px;
  }

  @media (--viewportLarge) {
    gap: 72px;
  }
}

.rightNavigation {
  justify-content: flex-end;
}

.navigationLink {
  /* Font */
  composes: topbarNavigationLink from global;
}

.logoText {
  /* Font */
  color: var(--marketplaceColor);
  text-transform: capitalize;
  letter-spacing: -0.01em;
  text-align: center;

  @media (min-width:768px) and (max-width:1200px) {
    display: none;

  }
}

/* .logoText, */
.logoLink {
  /* display: none; */

  /* @media (--viewportMLarge) { */
    display: flex;
  /* } */
}

.smartSearchLink{
  display: flex;
  gap: 8px;
  align-items: center;
  border: 1px solid var(--marketplaceColor);
  width: max-content;
  padding: 8px;
  font-family: var(--fontFamilyPrimary);
  font-weight: var(--fontWeightRegular);
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  white-space: nowrap;
  transition: var(--transitionStyleButton);
}
