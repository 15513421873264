.root {
}

.selectError {
  border-bottom: var(--borderErrorField);
}

.displayFlex {
  display: flex;
  align-items: center;
}

.label {
  color: #000;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 109%; /* 15.26px */
  letter-spacing: -0.28px;
}

.gapWithCursor {
  gap: 12px;
  cursor: pointer;
}
