@import '../../styles/customMediaQueries.css';

.root {
  /* Layout */
  display: flex;
  flex-direction: column;

  /* Style */
  padding: 16px;
  box-shadow: 0 0 0 1px;

  @media (--viewportMedium) {
    border-top: 0;
  }

  /* Remove link's hover effect */
  &:hover {
    text-decoration: none;
  }
}

.companyListingCard {
  & .title {
    font-size: 18px;
    line-height: 26px;
  }
}

.info {
  /* Layout */
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 14px 0 2px 0;
}

.mainInfo {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
}

.secondaryInfo {
  /* Layout */
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-top: 0;
  margin-bottom: 6px;
}

.title {
  /* Font */
  composes: h3 from global;

  margin-top: 0;
  margin-bottom: 0;

  /* Limit text to 2 lines */
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  word-break: break-all;
}

.city {
  /* Font */
  composes: h3 from global;
  font-size: 12px;
  line-height: 15px;

  margin-top: 0;
  margin-bottom: 0;
}

.infoIcons {
  display: flex;
  align-items: center;
  gap: 8px;
}

.longWord {
  /* fallback option */
  word-break: break-all;
  /* use break-word if available */
  word-break: break-word;
  hyphens: auto;
}

.industry {
  margin-top: 24px;

  @media (--viewportMedium) {
    /* margin-top: 48px; */
  }
}

.industryText {
  /* Layout */
  display: flex;
  align-items: center;
  gap: 8px;

  /* Font */
  font-size: 14px;
  line-height: 18px;
}

.mapPinList{
  background-color: rgb(248, 197, 197);
}

.isConnected{
  /* margin-top: 10px; */
  display: inline-flex;
align-items: center;
gap: 5px;
  border-radius: 50px;
border: 1px solid #222;
background: #FFD9D9;
width: fit-content;
padding: 8px 20px;
color: #222;

font-family: Inter;
font-size: 12px;
font-style: normal;
font-weight: 500;
line-height: normal;
}

.connectBtn{
  padding: 8px 20px;
  background: #222;
  text-transform: uppercase;
  color: white;
  width: fit-content;
}

.listCardBttm{
  margin-top: 24px;
  display: flex;
  justify-content: space-between;
  justify-items: center;
  align-items: center;
}

.listingCardDetails{
  padding: 0px 8px;
  border-radius: 50px;
  border: 1px solid #222;
  text-transform: capitalize;
}

.listCardMiddle{
  display:flex;
  align-items:center;
  gap:4px;
  margin-top:20px;
  flex-wrap: wrap;
}
